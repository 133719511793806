<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-card-actions
          class="cari-liste-card"
          id="printMe"
      >
        <b-row class="mt-50 mb-3">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">{{ $store.state.auth.workDay }} / Tarihli House Keeping Listesi</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="border-0 float-right"
                  @click="download('house-rapor')"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                Listeyi Yazdır
              </b-button>
            </b-col>
          </div>
        </b-row>
        <hr>
        <table class="table table-striped table-hover house-table" :class="width  < 640 ? 'mt-3':''">
          <thead>
          <tr>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              ODA NO
            </th>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              DURUM
            </th>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              Oda Tipi
            </th>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              Yatak
            </th>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              Temiz-Kirli
            </th>
            <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}" class="border">
              Misafirler
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(oda, index) in raporData"
              :key="index"
          >
            <td class="border">
              <a class="fw-bold text-dark">
                {{ oda.odaKodu }}
              </a>
            </td>
            <td class="border">
              <a class="fw-bold text-dark">
                {{ oda.odaDurum }}
              </a>
            </td>
            <td class="border">
              <a class="fw-bold text-dark">
                {{ oda.odaTipi }}
              </a>
            </td>
            <td class="border">
              <a class="fw-bold text-dark">
                {{ oda.yatak }}
              </a>
            </td>
            <td class="border">
              <a class="fw-bold text-dark">
                {{ oda.temizKirli }}
              </a>
            </td>
            <td class="border">
              <a class="fw-bold text-dark" style="font-size: 11px">
                {{
                  oda.misafirler != undefined ? Object.values(oda.misafirler)
                      .map(rez => Object.values(rez)
                          .map(misafir => `${misafir.konukAdi} ${misafir.konukSoyadi}`))
                      .join(', ') : ''
                }}
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </b-card-actions>
    </div>
    <!--:html-to-pdf-options="htmlToPdfOptions"-->
    <vue-html2pdf
        :show-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="house-rapor"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="house-rapor"
    >
      <section slot="pdf-content" class=" pl-2 pr-2 font-size-12" ref="house-rapor">
        <b-row class="mb-3" style="position:relative; margin: 10px;">
          <b-col cols="12" class="text-center mt-2">
            <small class="float-right">{{ currentDate }}</small>
            <h4 style="font-weight: 700">HouseKeeping Raporu</h4>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-row style="border:1px solid black;background-color: #EEEEEE;font-size: 12px;">
              <b-col style="padding:0;margin:0;" cols="1">
                Oda
              </b-col>
              <b-col style="padding:0;margin:0;" cols="2">
                Durum
              </b-col>
              <b-col style="padding:0;margin:0;" cols="2">
                Oda Tipi
              </b-col>
              <b-col style="padding:0;margin:0;white-space: nowrap" cols="1">
                Yatak S.
              </b-col>
              <b-col style="padding:0;margin:0;" cols="2">
                Temiz-Kirli
              </b-col>
              <b-col style="padding:0;margin:0;" cols="4">
                Misafirler
              </b-col>
            </b-row>
            <b-row v-for="item in raporData" :key="item.odaKodu"
                   style="border:1px solid black;font-size: 12px;border-top:none"
            >
              <b-col style="padding:2px 0;" cols="1">
                {{ item.odaKodu }}
              </b-col>
              <b-col style="padding:2px 0;" cols="2">
                {{ item.odaDurum }}
              </b-col>
              <b-col style="padding:2px 0;" cols="2">
                {{ item.odaTipi }}
              </b-col>
              <b-col style="padding:2px 0;" cols="1">
                {{ item.yatak }}
              </b-col>
              <b-col style="padding:2px 0;" cols="2">
                {{ item.temizKirli }}
              </b-col>
              <b-col style="padding:2px 0;" cols="4">
                {{
                  item.misafirler != undefined ? Object.values(item.misafirler)
                      .map(rez => Object.values(rez)
                          .map(misafir => `${misafir.konukAdi} ${misafir.konukSoyadi}`))
                      .join(', ') : ''
                }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </vue-html2pdf>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { useWindowSize } from '@vueuse/core'
import VueHtml2pdf from 'vue-html2pdf'
import store from '@/store'
import router from '@/router'
import jwt_decode from 'jwt-decode'

export default {
  components: {
    VueHtml2pdf,
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      htmlToPdfOptions: {
        margin: [11, 0, 11, 0],
      },
      spinner: true,
      allRoomList: [],
      odaBilgileri: [],
      raporData: [],
    }
  },
  computed: {
    currentDate() {
      const date = new Date()
      const day = String(date.getDate())
          .padStart(2, '0')
      const month = String(date.getMonth() + 1)
          .padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
  },
  beforeDestroy() {
    this.$store.commit('clearHouseKeepingList')
  },
  created() {
    this.$store.dispatch('houseKeepingList')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          this.raporOlustur()
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  methods: {
    raporOlustur() {
      const sf = this
      this.allRoomList = this.$store.state.more.room?.roomList
      if (this.allRoomList != undefined) {
        this.$store
            .dispatch('odaYonetim')
            .then(oda => {
              this.odaBilgileri = oda.odaListesi
              this.raporData = Object.values(this.allRoomList)
                  .map(item => {
                    let bugunCikacak
                    if (item.rezervasyonBilgileri != undefined) {
                      const veri = Object.values(item.rezervasyonBilgileri)
                          .find(veri => Object.values(veri)
                              .find(guest => guest.cikisTarih == this.$store.state.auth.workDay))
                      if (veri != undefined) bugunCikacak = true
                    }
                    return {
                      odaKodu: item.odaBilgileri.odaKodu,
                      temizKirli: this.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.kirliOda ? 'Kirli' : 'Temiz',
                      odaDurum: bugunCikacak ? 'Bugün Çıkacak' : this.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.yeniGiris ? 'Yeni Giriş' : this.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.mevcutKonaklama ? 'Dolu' : 'Boş',
                      odaTipi: item.odaBilgileri.odaTipi,
                      yatak: parseInt(this.odaBilgileri.find(oda => oda.odaKodu == item.odaBilgileri.odaKodu).yatak) + parseInt(this.odaBilgileri.find(oda => oda.odaKodu == item.odaBilgileri.odaKodu).eYatak),
                      misafirler: item.rezervasyonBilgileri,
                    }
                  })
                  .filter(item => !(item.temizKirli === 'Temiz' && item.misafirler === undefined))
            })
      } else {
        const data = {
          serviceName: 'dashBoard',
          crm_token: localStorage.getItem('otelUserToken'),
        }
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            sf.$store.commit('setRoom', res)
            sf.allRoomList = res.roomList
            sf.$store
                .dispatch('odaYonetim')
                .then(oda => {
                  sf.odaBilgileri = oda.odaListesi
                  sf.raporData = Object.values(sf.allRoomList)
                      .map(item => {
                        let bugunCikacak
                        if (item.rezervasyonBilgileri != undefined) {
                          const veri = Object.values(item.rezervasyonBilgileri)
                              .find(veri => Object.values(veri)
                                  .find(guest => guest.cikisTarih == sf.$store.state.auth.workDay))
                          if (veri != undefined) bugunCikacak = true
                        }
                        return {
                          odaKodu: item.odaBilgileri.odaKodu,
                          temizKirli: sf.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.kirliOda ? 'Kirli' : 'Temiz',
                          odaDurum: bugunCikacak ? 'Bugün Çıkacak' : sf.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.yeniGiris ? 'Yeni Giriş' : sf.$store.getters.houseKeepingListReturn[item.odaBilgileri.odaKodu]?.mevcutKonaklama ? 'Dolu' : 'Boş',
                          odaTipi: item.odaBilgileri.odaTipi,
                          yatak: parseInt(sf.odaBilgileri.find(oda => oda.odaKodu == item.odaBilgileri.odaKodu).yatak) + parseInt(sf.odaBilgileri.find(oda => oda.odaKodu == item.odaBilgileri.odaKodu).eYatak),
                          misafirler: item.rezervasyonBilgileri,
                        }
                      })
                      .filter(item => !(item.temizKirli === 'Temiz' && item.misafirler === undefined))
                })
          },
          dataType: 'json',
        })
      }
    },
    download(ref) {
      this.$refs[ref].generatePdf()
    },
    odaDurumState(oda) {
      if (oda.yeniGiris == true && oda.mevcutKonaklama == true && oda.kirliOda == true) {
        return 'Yeni Giriş | Konaklama Mevcut | Kirli Oda'
      }
      if (oda.yeniGiris == true && oda.mevcutKonaklama == true) {
        return 'Yeni Giriş | Konaklama Mevcut'
      }
      if (oda.yeniGiris == true) {
        return 'Yeni Giriş'
      }
      if (oda.mevcutKonaklama == true && oda.kirliOda == true) {
        return 'Konaklama Mevcut | Kirli Oda'
      }
      if (oda.kirliOda == true) {
        return 'Kirli Oda'
      }
      if (oda.mevcutKonaklama == true) {
        return 'Konaklama Mevcut'
      }
    },
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>

<style scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 3px !important;
  color: white;
}

[dir] .table th,
[dir] .table td {
  padding: 0 !important;
  width: 800px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

a {
  font-weight: 900;
}

.row {
  justify-content: space-between;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 4px !important;
}

.card-body {
  padding: 1rem 2rem !important;
}

</style>
<style>
/* Page break rules */
.page-break {
  page-break-before: always;
}

@media print {
  .page-break {
    display: block;
    height: 0;
    page-break-before: always;
    page-break-after: always;
  }
}
</style>
